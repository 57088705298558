.sweet-alert {
	&:focus {
		outline: none;
	}
	input.form-control {
		border: 0;
		background-image: linear-gradient(#9c27b0, #9c27b0),
			linear-gradient(#d2d2d2, #d2d2d2);
		background-size: 0 2px, 100% 1px;
		background-repeat: no-repeat;
		background-position: center bottom, center calc(100% - 1px);
		background-color: rgba(0, 0, 0, 0);
		transition: background 0s ease-out;
		float: none;
		box-shadow: none;
		border-radius: 0;
		font-weight: 400;
		width: 100%;
		height: 36px;
		padding: 7px 0;
		font-size: 14px;
		line-height: 1.428571429;
		display: block;
		width: 100%;
		color: #555;
	}
	input.form-control:focus {
		outline: none;
		background-image: linear-gradient(#9c27b0, #9c27b0),
			linear-gradient(#d2d2d2, #d2d2d2);
		background-size: 100% 2px, 100% 1px;
		box-shadow: none;
		transition-duration: 0.3s;
	}
}
