/* @font-face {
  font-family: "GothamRnd";
  src: local("GothamRnd"),
    url(./assets/fonts/GothamRnd-Light.otf) format("truetype");
  font-weight: lighter;
  font-style: normal;
} */
@font-face {
	font-family: "GothamRnd";
	src: local("GothamRnd"),
		url(./assets/fonts/GothamRnd-Medium.otf) format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "GothamRnd";
	src: local("GothamRnd"),
		url(./assets/fonts/GothamRnd-Bold.otf) format("truetype");
	font-weight: bold;
	font-style: normal;
}
