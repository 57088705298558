/*! nouislider - 14.0.2 - 6/28/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
/*

This file was modified by Creative-Tim

*/
.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base,
.noUi-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
	overflow: hidden;
	z-index: 0;
}
.noUi-connect,
.noUi-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	-webkit-transform-style: preserve-3d;
	transform-origin: 0 0;
	transform-style: flat;
}
.noUi-connect {
	height: 100%;
	width: 100%;
}
.noUi-origin {
	height: 10%;
	width: 10%;
}
/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
	left: auto;
	right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
	width: 0;
}
.noUi-horizontal .noUi-origin {
	height: 0;
}
.noUi-handle {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	position: absolute;
}
.noUi-touch-area {
	height: 100%;
	width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
	-webkit-transition: transform 0.3s;
	transition: transform 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 2px;
}
.noUi-horizontal .noUi-handle {
	box-sizing: border-box;
	width: 14px;
	height: 14px;
	left: -10px;
	top: -6px;
	cursor: pointer;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	border: 1px solid #9c27b0;
	background: #fff;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 15px;
	height: 15px;
	left: 0px;
	top: -7px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
	right: -4px;
	left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
	background-color: #c8c8c8;
	border-radius: 3px;
}
.noUi-connects {
	border-radius: 3px;
}
.noUi-connect {
	// background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
	cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: ns-resize;
}
.noUi-handle {
	border-radius: 3px;
	background: #fff;
	cursor: default;
	box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}
.noUi-active {
	box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
	background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
	cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-pips {
	position: absolute;
	color: #999;
}
/* Values;
 *
 */
.noUi-value {
	position: absolute;
	white-space: nowrap;
	text-align: center;
}
.noUi-value-sub {
	color: #ccc;
	font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
	position: absolute;
	background: #ccc;
}
.noUi-marker-sub {
	background: #aaa;
}
.noUi-marker-large {
	background: #aaa;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
	padding: 10px 0;
	height: 80px;
	top: 100%;
	left: 0;
	width: 100%;
}
.noUi-value-horizontal {
	-webkit-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
	-webkit-transform: translate(50%, 50%);
	transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
	-webkit-transform: translate(0, 50%);
	transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
.noUi-tooltip {
	display: block;
	position: absolute;
	border: 1px solid #d9d9d9;
	border-radius: 3px;
	background: #fff;
	color: #000;
	padding: 5px;
	text-align: center;
	white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	left: 50%;
	bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
	right: 120%;
}

.noUi-target {
	& .noUi-handle {
		border: 1px solid #333;
	}

	&.slider-primary {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-primary;
		}

		& .noUi-handle {
			border-color: $brand-primary;
		}
	}

	&.slider-info {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-info;
		}

		& .noUi-handle {
			border-color: $brand-info;
		}
	}
	&.slider-success {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-success;
		}

		& .noUi-handle {
			border-color: $brand-success;
		}
	}
	&.slider-warning {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-warning;
		}

		& .noUi-handle {
			border-color: $brand-warning;
		}
	}
	&.slider-danger {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-danger;
		}

		& .noUi-handle {
			border-color: $brand-danger;
		}
	}
	&.slider-rose {
		& .noUi-connect,
		&.noUi-connect {
			background-color: $brand-rose;
		}

		& .noUi-handle {
			border-color: $brand-rose;
		}
	}
}
